import React from "react";

// Advantages Component
const Advantages = () => {
  return (
    <section className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="text-danger display-5 fw-bold">
          Why Partner with 6toour
        </h2>
        <p className="text-muted mb-5">
          Discover what makes us a leading choice in corporate travel solutions.
        </p>
        <div className="row">
          {advantagesList.map((adv, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index}>
              <div className="card h-100 shadow border-0">
                <div className="card-body">
                  <h5 className="card-title text-danger fw-bold">
                    {adv.title}
                  </h5>
                  <p className="card-text text-muted">{adv.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const advantagesList = [
  {
    title: "Decade of Excellence",
    description:
      "Over a decade of expertise in providing seamless corporate travel worldwide.",
  },
  {
    title: "Trusted by 10,000+ Clients",
    description:
      "Thousands trust us yearly for corporate travel management without hassles.",
  },
  {
    title: "Exclusive Travel Deals",
    description:
      "Benefit from exclusive rates through our partnerships with top providers.",
  },
  {
    title: "Global Network Coverage",
    description:
      "Our global reach ensures smooth travel experiences wherever you go.",
  },
];

export default Advantages;
