import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <h1 className="text-center display-4 text-danger fw-bold mb-4">
          About 6toour
        </h1>
        <hr
          className="bg-danger mx-auto"
          style={{ width: "70px", height: "3px" }}
        />

        {/* Mission Statement */}
        <section className="my-5 py-5 rounded shadow bg-dark text-light text-center">
          <h2 className="fw-bold text-warning mb-3">Our Mission</h2>
          <p className="lead">
            At 6toour, we are dedicated to revolutionizing business travel
            through innovative, technology-driven solutions. We help businesses
            achieve seamless travel experiences, tailored to meet the unique
            needs of each client.
          </p>
        </section>

        {/* Our Platform */}
        <section className="my-5 d-md-flex align-items-center bg-light rounded shadow p-4">
          <div className="col-md-6 p-3">
            <h2 className="fw-bold text-danger">Advanced Travel Solutions</h2>
            <p>
              Our platform offers a comprehensive suite of tools designed to
              make corporate travel planning efficient and cost-effective.
              Leveraging the latest in travel tech, we ensure each client’s
              unique requirements are met with precision.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="/images/our-platform.jpg"
              alt="Our Platform"
              className="img-fluid rounded shadow-sm"
            />
          </div>
        </section>

        {/* Sustainability */}
        <section className="my-5 py-5 bg-white rounded shadow-sm text-center">
          <h2 className="fw-bold text-warning">Commitment to Sustainability</h2>
          <p className="lead">
            Our commitment to sustainability means actively choosing
            eco-friendly options and minimizing carbon footprints. We work with
            partners who prioritize environmental responsibility, ensuring that
            our impact is a positive one.
          </p>
        </section>

        {/* Meetings & Events */}
        <section className="my-5 d-md-flex align-items-center bg-light rounded shadow p-4">
          <div className="col-md-6 text-center">
            <img
              src="/images/meetings-events.jpg"
              alt="Meetings & Events"
              className="img-fluid rounded shadow-sm"
            />
          </div>
          <div className="col-md-6 p-3">
            <h2 className="fw-bold text-danger">
              Innovative Meetings & Events
            </h2>
            <p>
              Our Meetings & Events service simplifies the planning of virtual,
              hybrid, and in-person gatherings, allowing your team to focus on
              meaningful connections and impactful interactions. We take care of
              the logistics, so you can focus on your goals.
            </p>
          </div>
        </section>

        {/* Key Facts */}
        <section className="my-5 py-5 bg-dark rounded shadow-sm text-center">
          <h2 className="fw-bold text-warning">Key Facts & Figures</h2>
          <p className="lead text-light">
            Operating across over 150 countries, 6toour provides a reliable,
            state-of-the-art travel experience. From our extensive network to
            advanced tech solutions, we are here to support your corporate
            travel needs at every turn.
          </p>
        </section>

        {/* Join Our Team */}
        <section className="my-5 text-center py-5 bg-white rounded shadow-sm">
          <h2 className="fw-bold text-danger">Join Our Team</h2>
          <p className="lead text-muted">
            Join us in redefining corporate travel. If you’re passionate about
            innovative solutions and delivering exceptional service, explore
            career opportunities at 6toour today.
          </p>
          <Link to="/careers" className="btn btn-warning mt-3 px-4 py-2">
            Explore Careers
          </Link>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
