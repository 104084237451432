import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Solutions = () => {
  return (
    <section className="terms-and-conditions py-5 bg-white text-start">
      <div className="container">
        <h2 className="mb-4 text-center">CONTRACT OF RESPONSIBILITIES OF THE DATA PROCESSOR</h2>
        <p className="text-center fst-italic">(pursuant to Article 28 of EU Regulation 2016/679 – GDPR)</p>
        <p className="mt-4">
          By digitally accepting this agreement, your Travel Agency (henceforth “Data Controller”) enters into a contract with Adonnik Ltd (henceforth “Data Processor”), located at Example Street, London, UK, as represented by its legal authority.
        </p>
        <h4 className="mt-5">Whereas</h4>
        <ul>
          <li>
            In accordance with Article 28 of EU Regulation 2016/679, the Data Processor ensures the adoption of technical and organizational measures to fully comply with EU regulations, safeguarding the rights of concerned parties.
          </li>
          <li>
            The data shared within this contractual agreement is governed by this document, binding the Data Processor to follow the Data Controller's instructions and ensuring security measures, as required by GDPR.
          </li>
          <li>
            Adonnik Ltd performs intermediary services, connecting travel agencies with operators, accommodation providers, and more, facilitating seamless transactions and services within the tourism industry.
          </li>
        </ul>

        <h4 className="mt-5">Terms of Data Processing</h4>
        <p>
          By entering this agreement, the Data Controller appoints Adonnik Ltd as the Data Processor to handle necessary operations related to the services provided. The processing activities will be conducted at Adonnik’s facilities, adhering to both digital and physical security measures.
        </p>
        <p>
          The Data Processor commits to the following responsibilities, ensuring strict compliance with Article 32 of EU Regulation 2016/679:
        </p>
        <ul>
          <li>Personal data will only be processed as needed for the completion of the service requested by the Data Controller.</li>
          <li>All personnel involved in data processing will receive specialized training on handling and securing personal data, strictly following the outlined procedures.</li>
          <li>Technical and organizational safeguards will be implemented to protect against unauthorized access, loss, or other risks associated with data processing.</li>
          <li>The Data Processor will cooperate with the Data Controller to maintain regulatory compliance, providing support for audits and responding promptly to inquiries from regulatory authorities.</li>
        </ul>

        <h4 className="mt-5">Duration and Termination of Agreement</h4>
        <p>
          This agreement remains effective for the duration of the contractual relationship between the Data Controller and Data Processor. Upon the termination of the contract or dissolution of the relationship, all data will be returned or securely destroyed, except as required by applicable law.
        </p>

        <h4 className="mt-5">Indemnity</h4>
        <p>
          The Data Controller holds responsibility for ensuring compliance with GDPR obligations. Any failure to meet these responsibilities will result in the indemnification of Adonnik Ltd against any liabilities, damages, or claims arising from such non-compliance.
        </p>

        <h4 className="mt-5">Responsibilities of the Data Processor</h4>
        <ul>
          <li>
            The Data Processor is authorized to perform only the tasks specified under this agreement, ensuring all actions align with the Controller’s instructions.
          </li>
          <li>
            All data handling will adhere to legal and ethical standards, processed only to the extent required by the service.
          </li>
          <li>
            In case of cessation of processing, the Data Processor will either return or destroy the data, as per the legal or contractual obligations.
          </li>
        </ul>

        <p className="text-muted fst-italic mt-5">
          This agreement is governed by the terms set forth and will remain effective for the specified duration, ensuring mutual cooperation and compliance with data protection regulations.
        </p>
      </div>
    </section>
  );
};

export default Solutions;
