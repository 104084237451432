import React from "react";
import { Footer, Navbar } from "../components";
import PartnershipSection from "../components/PartnershipSection";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Marquee from "react-fast-marquee";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid p-0">
        {/* Hero Section with Full-Width Slider */}
        <section
          className="hero-section text-center d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundImage: "url('/images/banner-new.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            color: "#fff",
            position: "relative",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 87, 34, 0.2)", // Красно-оранжевый оттенок
            }}
          ></div>
          <div style={{ position: "relative", zIndex: 1, maxWidth: "700px" }}>
            <h1 className="display-4 fw-bold">
              Welcome to 6toour: Your Global Travel Solution
            </h1>
            <p className="lead mt-3 mb-4">
              Access over 2.5 million hotels and ground services worldwide.
            </p>
            <Link to="/contact" className="btn btn-danger btn-lg">
              Register Now
            </Link>
          </div>
        </section>

        {/* About Section */}
        <section className="about-section py-5 bg-light text-center">
          <div className="container">
            <h2>About 6toour</h2>
            <p className="lead">
              6toour provides an advanced hotel booking platform with 24/7
              support for travel agencies and tour operators globally.
            </p>
          </div>
        </section>

        {/* Services Section */}
        <section className="services-section py-5 bg-white text-center">
          <div className="container">
            <h2>Our Key Services</h2>
            <div className="row mt-4">
              <div className="col-md-4">
                <img
                  src="/images/hotel-icon.png"
                  alt="Hotels"
                  width="80"
                  className="mb-3"
                />
                <h4>Hotels</h4>
                <p>
                  Over 2.5 million hotel listings worldwide at the best rates.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="/images/flights-icon.png"
                  alt="Flights"
                  width="80"
                  className="mb-3"
                />
                <h4>Flights</h4>
                <p>
                  Book flights with competitive fares for global destinations.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="/images/transfers-icon.png"
                  alt="Transfers"
                  width="80"
                  className="mb-3"
                />
                <h4>Transfers</h4>
                <p>
                  Reliable ground transfers for a seamless travel experience.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Partners Marquee */}
        <section className="partners-marquee bg-light text-dark py-4">
          <Marquee speed={50} pauseOnHover gradient={false}>
            <img
              src="/images/logo1.webp"
              alt="Partner 1"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo2.webp"
              alt="Partner 2"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo3.webp"
              alt="Partner 3"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo4.webp"
              alt="Partner 4"
              className="mx-4"
              width="120"
            />
            {/* Add more partner logos as needed */}
          </Marquee>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section py-5 bg-danger text-white text-center">
          <h2>What Our Partners Say</h2>
          <blockquote className="blockquote mt-4">
            <p>
              "6toour offers unparalleled access to global travel services with
              exceptional support."
            </p>
            <footer className="blockquote-footer text-light">
              CEO, International Travel Solutions
            </footer>
          </blockquote>
        </section>

        {/* Call to Action */}
        <section className="cta-section py-5 bg-light text-center">
          <h2>Become a 6toour Partner</h2>
          <p>
            Ready to elevate your travel business? Contact us to explore
            tailored solutions.
          </p>
          <Link to="/contact" className="btn btn-danger btn-lg">
            Get Started
          </Link>
        </section>
      </div>
      <PartnershipSection />
      <Footer />
    </>
  );
};

export default HomePage;
