import React from "react";
import { Footer, Navbar } from "../components";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <h1 className="text-center display-4 text-danger fw-bold mb-4">
          Contact Us
        </h1>
        <hr
          className="bg-danger mx-auto"
          style={{ width: "70px", height: "3px" }}
        />

        {/* Contact Information */}
        <section className="my-5 py-4 rounded shadow bg-dark text-light text-center">
          <h2 className="fw-bold text-warning">Get in Touch</h2>
          <p className="lead mb-4">
            We are here to assist with any inquiries, support, or information
            you may need. Connect with us through any of the channels below.
          </p>
          <div className="row">
            <div className="col-md-4">
              <h5 className="text-warning fw-bold">Address</h5>
              <p>
                12544 Chicago Ridge 122
                <br />
                Chicago, United-States
              </p>
            </div>
            <div className="col-md-4">
              <h5 className="text-warning fw-bold">Phone</h5>
              <p>+1 847-330-1537</p>
            </div>
            <div className="col-md-4">
              <h5 className="text-warning fw-bold">Email</h5>
              <p>support@6toour.com</p>
            </div>
          </div>
        </section>

        {/* Contact Form */}
        <section className="my-5 py-4 bg-light rounded shadow">
          <h2 className="fw-bold text-danger text-center mb-4">
            Send Us a Message
          </h2>
          <form className="px-4">
            <div className="mb-3">
              <label htmlFor="name" className="form-label fw-bold text-muted">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Your name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label fw-bold text-muted">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Your email"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="subject"
                className="form-label fw-bold text-muted"
              >
                Subject
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="Message subject"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="message"
                className="form-label fw-bold text-muted"
              >
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="5"
                placeholder="Your message"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-danger px-4 py-2">
              Send Message
            </button>
          </form>
        </section>

        {/* Support Section */}
        <section className="my-5 py-5 bg-white rounded shadow text-center">
          <h2 className="fw-bold text-danger">Customer Support</h2>
          <p className="lead text-muted">
            Our support team is available 24/7 to assist with any issues or
            questions you may have. Contact us anytime, and we'll ensure you
            receive timely, professional support.
          </p>
          <a href="tel:++1 847-330-1537" className="btn btn-warning mt-3 px-4 py-2">
            Call Support
          </a>
        </section>

        {/* Map Section */}
        <section className="my-5 py-5 bg-light rounded shadow text-center">
          <h2 className="fw-bold text-danger">Visit Our Office</h2>
          <p className="text-muted mb-4">
            We welcome you to visit our office for a personal consultation. Find
            us at our main office location.
          </p>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.852975060256!2d-122.41941508468148!3d37.77492977975892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858064a5a4ef5f%3A0x9990f8df3deaf8b8!2sBusiness%20Ave%2C%20San%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1626337849015!5m2!1sen!2sus"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
