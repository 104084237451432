import React from "react";

const Footer = () => {
  return (
    <footer
      className="footer text-white text-center py-5"
      style={{
        backgroundColor: "#FF6347",
        background: "linear-gradient(135deg, #FF4500 0%, #FF6347 100%)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">About 6toour</h5>
            <p>
              6toour offers tailored B2B travel solutions with a focus on
              seamless and memorable experiences.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="/"
                  className="text-decoration-none text-white hover-underline"
                  style={{ color: "#FFD700" }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="text-decoration-none text-white hover-underline"
                  style={{ color: "#FFD700" }}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/terms"
                  className="text-decoration-none text-white hover-underline"
                  style={{ color: "#FFD700" }}
                >
                  Terms
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-decoration-none text-white hover-underline"
                  style={{ color: "#FFD700" }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Connect with Us</h5>
            <div>
              <a
                className="text-white fs-4 me-4 hover-zoom"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="text-white fs-4 hover-zoom"
                href="https://facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4 bg-light" />
        <p className="mb-0">© 2024 6toour. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
