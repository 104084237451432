import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg sticky-top shadow-lg py-3"
      style={{ backgroundColor: "#FF4500" }}
    >
      <div className="container">
        <NavLink
          className="navbar-brand fw-bold fs-2 text-white"
          to="/"
          style={{
            color: "#FFFFFF",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
          }}
        >
          6toour
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav mx-auto text-center">
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 mx-3"
                to="/"
                activeClassName="active"
                style={{ color: "#FFD700" }}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 mx-3"
                to="/terms"
                activeClassName="active"
                style={{ color: "#FFD700" }}
              >
                Terms
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 mx-3"
                to="/about"
                activeClassName="active"
                style={{ color: "#FFD700" }}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 mx-3"
                to="/contact"
                activeClassName="active"
                style={{ color: "#FFD700" }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="btn-group">
            <NavLink
              to="/login"
              className="btn btn-outline-light rounded-pill mx-1"
            >
              Login
            </NavLink>
            <NavLink
              to="/register"
              className="btn btn-light text-danger rounded-pill mx-1"
            >
              Register
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
