import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import Advantages from "../components/Advantages";

// Register Component
const Register = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <h1 className="text-danger text-center display-6">
          Create Your 6toour Account
        </h1>
        <hr className="w-50 mx-auto mb-4 text-danger" />
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card shadow-sm p-4">
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label text-muted">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Your Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label text-muted">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="form-label text-muted">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-danger w-100">
                    Register
                  </button>
                </div>
              </form>
              <p className="text-center mt-4">
                Already have an account?{" "}
                <Link to="/login" className="text-danger fw-bold">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Advantages />
        <Footer />
      </div>
    </>
  );
};

export default Register;
