import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PartnershipSection = () => {
  const [formData, setFormData] = useState({
    agencyName: "",
    city: "",
    country: "",
    bookingEmail: "",
    website: "",
    managerName: "",
    managerSurname: "",
    phoneNumber: "",
    referralSource: "",
    notes: "",
    acceptTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic for form submission
    console.log("Form submitted:", formData);
  };

  return (
    <section className="partnership-section py-5 bg-light text-center">
      <div className="container">
        <h2 className="mb-4">REGISTER AND SEARCH FOR HOTELS ON 6toour.COM</h2>
        <p className="lead">
          Please fill in the form below. Our Sales Managers will contact you
          within 2 working days.
        </p>
        <form
          onSubmit={handleSubmit}
          className="text-start mx-auto"
          style={{ maxWidth: "600px" }}
        >
          {/* Agency Name */}
          <div className="mb-3">
            <label className="form-label">Agency name *</label>
            <input
              type="text"
              className="form-control"
              name="agencyName"
              required
              value={formData.agencyName}
              onChange={handleChange}
            />
          </div>

          {/* City */}
          <div className="mb-3">
            <label className="form-label">City *</label>
            <input
              type="text"
              className="form-control"
              name="city"
              required
              value={formData.city}
              onChange={handleChange}
            />
          </div>

          {/* Country */}
          <div className="mb-3">
            <label className="form-label">Country *</label>
            <input
              type="text"
              className="form-control"
              name="country"
              required
              value={formData.country}
              onChange={handleChange}
            />
          </div>

          {/* Booking Email */}
          <div className="mb-3">
            <label className="form-label">Booking email *</label>
            <input
              type="email"
              className="form-control"
              name="bookingEmail"
              required
              value={formData.bookingEmail}
              onChange={handleChange}
            />
          </div>

          {/* Website */}
          <div className="mb-3">
            <label className="form-label">Website</label>
            <input
              type="url"
              className="form-control"
              name="website"
              value={formData.website}
              onChange={handleChange}
            />
          </div>

          {/* Booking Manager's Name */}
          <div className="mb-3">
            <label className="form-label">Booking Manager’s name *</label>
            <input
              type="text"
              className="form-control"
              name="managerName"
              required
              value={formData.managerName}
              onChange={handleChange}
            />
          </div>

          {/* Booking Manager's Surname */}
          <div className="mb-3">
            <label className="form-label">Booking Manager’s surname *</label>
            <input
              type="text"
              className="form-control"
              name="managerSurname"
              required
              value={formData.managerSurname}
              onChange={handleChange}
            />
          </div>

          {/* Telephone Number */}
          <div className="mb-3">
            <label className="form-label">Telephone number *</label>
            <input
              type="tel"
              className="form-control"
              name="phoneNumber"
              required
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="+39-123456"
            />
          </div>

          {/* How did you find out about us? */}
          <div className="mb-3">
            <label className="form-label">
              How did you find out about us? *
            </label>
            <select
              className="form-select"
              name="referralSource"
              required
              value={formData.referralSource}
              onChange={handleChange}
            >
              <option value="">Select an option</option>
              <option value="6toour manager">6toour manager</option>
              <option value="email">Email</option>
              <option value="word of mouth">Word of mouth</option>
              <option value="Google">Google</option>
              <option value="Bing">Bing</option>
              <option value="Baidu">Baidu</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Facebook">Facebook</option>
              <option value="YouTube">YouTube</option>
              <option value="X">X (ex Twitter)</option>
              <option value="online forums">Online forums</option>
              <option value="newspapers">Newspapers</option>
              <option value="other">Other</option>
            </select>
          </div>

          {/* Notes */}
          <div className="mb-3">
            <label className="form-label">Notes</label>
            <textarea
              className="form-control"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              rows="3"
            ></textarea>
          </div>

          {/* Accept Terms */}
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="acceptTerms"
              required
              checked={formData.acceptTerms}
              onChange={handleChange}
            />
            <label className="form-check-label">
              Read and accept the contract of responsibilities of the data
              processor *
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn-danger btn-lg w-100 mt-3">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default PartnershipSection;
